import "./App.css";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CacheBuster from 'react-cache-buster';
import { CookieSetting } from "cookiesetting-component";
import appversion from '../package.json';
import Spinner from "react-bootstrap/Spinner";
import MediaQuery from "react-responsive";
import UserWayAccessibilityMenu from "./components/UserWayAccessibilityMenu";

// Lazy loaded components
const Banner = lazy(() => import('./components/Banner'));
const PerfectPlace = lazy(() => import('./components/PerfectPlace'));
const PerfectPlaceMobile = lazy(() => import('./components/PerfectPlaceMobile'));
const Benefits = lazy(() => import('./components/Benefits'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const Disclosures = lazy(() => import('./components/Disclosures'));
const CyberSecurity = lazy(() => import('./components/CyberSecurity'));
const AccessibilityStatement = lazy(() => import('./components/AccessibilityStatement'));
const NMLSInformation = lazy(() => import('./components/NMLSInformation'));
const Terms = lazy(() => import('./components/TermsConditions'));
const Sitemap = lazy(() => import('./components/Sitemap'));
const Footer = lazy(() => import('./components/Footer'));
const Customer = lazy(() => import('./components/Customer/index'));
const CareerPage = lazy(() => import('./components/CareerPage/index'));
const ContactUs = lazy(() => import('./components/ContactUsPage'));
const Texasdisclosure = lazy(() => import('./components/Texasdisclosure/index'));
const Notfoundpage = lazy(() => import('./components/Notfoundpage/index'));
const Disclaimer = lazy(() => import('./components/Disclaimer/Disclaimer'));

// Loading spinner component for Suspense fallback
const LoadingSpinner = () => (
  <div className="text-center" style={{ padding: "50px 0" }}>
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [disclaimerData, setDisclaimerData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetch(process.env.REACT_APP_CDN_JSON);
        const jsonData = await data.json();
        setDisclaimerData(jsonData);
      } catch (error) {
        console.log("Data Not Found", error);
      }
    };

    fetchData();
  }, []);

  return (
    <CacheBuster
      currentVersion={appversion.version}
      isEnabled={true}
      isVerboseMode={false}
      metaFileDirectory={process.env.PUBLIC_URL}
    >
      <div className="App">
        <Suspense fallback={<LoadingSpinner />}>
          <Banner />
        </Suspense>
        
        <MediaQuery minWidth={992}>
          <Suspense fallback={<div />}>
            <PerfectPlace />
          </Suspense>
        </MediaQuery>
        
        <MediaQuery maxWidth={991}>
          <Suspense fallback={<div />}>
            <PerfectPlaceMobile />
          </Suspense>
        </MediaQuery>
        
        {disclaimerData.disclaimer && (
          <Suspense fallback={<div/>}>
            <Disclaimer data={disclaimerData} />
          </Suspense>
        )}
        
        <Suspense fallback={<div />}>
          <Footer />
        </Suspense>
      </div>
    </CacheBuster>
  );
}

function App() {
  const [disclaimerData, setDisclaimerData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetch(process.env.REACT_APP_CDN_JSON);
        const jsonData = await data.json();
        setDisclaimerData(jsonData);
      } catch (error) {
        console.log("Data Not Found", error);
      }
    };

    fetchData();
  }, []);

  return (
    <CacheBuster
      currentVersion={appversion.version}
      isEnabled={true}
      isVerboseMode={false}
      metaFileDirectory={process.env.PUBLIC_URL}
    >
      <CookieSetting 
        privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK} 
        configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
        analytics={process.env.REACT_APP_ANALYTICS_PATH}
      />
      <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <div className="userwaydivmobile">
          <UserWayAccessibilityMenu color="black" width={40} height={40} />
        </div>
        
        {/* Main router with proper suspense boundary */}
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/privacy-policy" element={<PrivacyPolicy data={disclaimerData} />} />
            <Route path="/cyber-security" element={<CyberSecurity data={disclaimerData} />} />
            <Route path="/accessibility-statement" element={<AccessibilityStatement data={disclaimerData} />} />
            <Route path="/nmls-information" element={<NMLSInformation data={disclaimerData} />} />
            <Route path="/terms" element={<Terms data={disclaimerData} />} />
            <Route path="/sitemap" element={<Sitemap data={disclaimerData} />} />
            <Route path="/customers" element={<Customer data={disclaimerData} />} />
            <Route path="/careers" element={<CareerPage data={disclaimerData} />} />
            <Route path="/contactus" element={<ContactUs data={disclaimerData} />} />
            <Route path="/txdis" element={<Texasdisclosure data={disclaimerData} />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Notfoundpage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </CacheBuster>
  );
}

export default App;